const MESSAGE = {
  EN: {
    page_title: 'Project Management',
    add_new_site_btn_txt: 'Add New Site',
    delete_project_btn_txt: 'Delete This Project',
    edit_txt: 'Edit',
    cancel_txt: 'Cancel',
    save_txt: 'Save',
    delete_txt: 'Delete',
    delete_project_msg_01: 'Are you sure you want to delete',
    delete_project_msg_02: 'This action cannot be undone. Please type in the name of the project to confirm.',
    untitle_txt: 'Untitled',
    project_name_input_label: 'PROJECT NAME',
    project_name_input_placeholder: 'Project name',
    project_screenshot_input_label: 'PROJECT IMAGE',
    project_screenshot_input_placeholder: 'Image URL',
    delete_dialog_title: 'Delete Project ?',
    delete_dialog_message: 'Are you sure to delete ',
    delete_site_title: 'Delete Site ?',
    delete_site_message: 'Are you sure to delete site',
    delete_site_cancel_btn: 'Cancel',
    delete_site_confirm_btn: 'Confirm',
    input_site_name_label: 'SITE NAME',
    input_site_name_placeholder: 'Site name',
    dialog_update_project_error_txt: 'Invalid name.',
    dialog_update_project_img_url_error_txt: 'Invalid URL',
    dialog_create_site_error_txt: 'Invalid name.',
    error_dialog_title_default: 'Something went wrong',
    error_dialog_message_default: `There was a problem connection to the server. 
    Please try again or contact support.`,
    try_again_button: 'Try again'
  },
  TH: {
    page_title: 'จัดการโปรเจกต์',
    add_new_site_btn_txt: 'สร้างไซต์ใหม่',
    delete_project_btn_txt: 'ลบโปรเจกต์',
    edit_txt: 'แก้ไข',
    cancel_txt: 'ยกเลิก',
    save_txt: 'บันทึก',
    delete_txt: 'ลบ',
    delete_project_msg_01: 'คคุณแน่ใจหรือไม่ว่าต้องการลบ',
    delete_project_msg_02: 'การดำเนินการนี้ไม่สามารถเปลี่ยนแปลงได้ กรุณากรอกชื่อโปรเจกต์เพื่อทำการยืนยัน',
    untitle_txt: 'ไม่มีชื่อ',
    project_name_input_label: 'ชื่อโปรเจกต์',
    project_name_input_placeholder: 'ชื่อ',
    project_screenshot_input_label: 'PROJECT IMAGE',
    project_screenshot_input_placeholder: 'Image URL',
    delete_dialog_title: 'ลบโปรเจกต์ ?',
    delete_dialog_message: 'ต้องการที่จะลบ ',
    delete_site_title: 'ลบไซต์ ?',
    delete_site_message: 'คุณต้องการที่จะลบไซต์หรือไม่',
    delete_site_cancel_btn: 'ยกเลิก',
    delete_site_confirm_btn: 'ยืนยัน',
    input_site_name_label: 'ชื่อไซต์',
    input_site_name_placeholder: 'ไซต์',
    dialog_update_project_error_txt: 'ชื่อไม่ถูกต้อง',
    dialog_update_project_img_url_error_txt: 'URL ไม่ถูกต้อง',
    error_dialog_title_default: 'ข้อผิดพลาด',
    error_dialog_message_default: `เกิดปัญหาในการเชื่อมต่อกับเซิร์ฟเวอร์
    กรุณาลองอีกครั้งหรือติดต่อฝ่ายสนับสนุน`,
    try_again_button: 'ลองอีกครั้ง'
  }
}

export default MESSAGE
