/* eslint-disable indent */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as ProjectActions from './actions'

import Button from '../../components/Button/Loadable'
import TextButton from '../../components/TextButton/Loadable'
import Breadcrumb from '../../components/Breadcrumb/Loadable'
import ModalOverlay from '../../components/ModalOverlay/Loadable'
import ModalNotification from '../../components/ModalNotification/Loadable'
import Spinner from '../../components/Spinner/Loadable'
import ProjectEditor from '../../components/ProjectEditor/Loadable'
import TextInput from '../../components/TextInput/Loadable'
import fetchErrorHandler from '../../utils/fetchErrorHandler'

import ProjectManagementDetailStyled from './styledComponent'

import Image from '../../asset/images/default.png'

import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE, FRONTEND_ROUTES } from '../../utils'

import MESSAGE from './message'

class ProjectPageLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projectDetail: undefined,
      breadcreumbProjectName: '',
      showLoading: false,
      isEditingProjectName: false,
      showDeleteProjectModal: false,
      deleteProjectName: '',
      projectNameInput: '',
      isProjectNameValid: undefined,
      showUpdateProjectModal: false,
      newSiteName: '',
      isSiteNameValid: undefined,
      showCreateSiteModal: false,
      projectScreenshotURL: '',
      isProjectScreenshotURLValid: undefined,
      showEditProjectScreenshotModal: false,
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      }
    }
  }

  async componentDidMount() {
    const projectID = this.props.match.params.projectID
    await this.props.setCurrentProjectCookie(projectID)
    this.props.loadProjectDetail(projectID)
    this.props.loadListSite()
  }

  componentDidUpdate(prevProps, prevState) {
    this._handleFetchLoadProjectDetail(prevProps)
    this._handleFetchUpdateProjectDetail(prevProps)
    this._handleFetchDeleteProjectDetail(prevProps)
    this._handleFetchLoadListSite(prevProps)
    this._handleFetchCreateSite(prevProps)
    this._handleFetchDeleteSite(prevProps)
    this._handleFetchUpdateSite(prevProps)
    this._handleFetchCreateArea(prevProps)
    this._handleFetchUpdateArea(prevProps)
    this._handleFetchDeleteArea(prevProps)
    this._handleFetchCreateCamera(prevProps)
    this._handleFetchDeleteCamera(prevProps)
    this._handleFetchUpdateCamera(prevProps)
    this._handleFetchMoveCameraBetweenArea(prevProps)
    this._handleFetchCreateSpeaker(prevProps)
    this._handleFetchUpdateSpeaker(prevProps)
    this._handleFetchDeleteSpeaker(prevProps)
    this._checkPermission()
  }

  _checkPermission() {
    const isAdmin = this.props.currentUser?.admin
    const isManager = this.props.currentUser?.manager
    const isUser = !(isAdmin || isManager)
    if (isUser) {
      this.props.history.push(FRONTEND_ROUTES.selectSiteRoute)
    }
  }

  _handleFetchDeleteSpeaker(prevProps) {
    if (
      prevProps.projectDetailPage.deleteSpeaker.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.projectDetailPage.deleteSpeaker.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.projectDetailPage.deleteSpeaker.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.deleteSpeaker.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
    }
    if (
      prevProps.projectDetailPage.deleteSpeaker.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.deleteSpeaker.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.handleShowDefaultFailureModal(this.props.projectDetailPage.deleteSpeaker.error.status)
    }
  }

  _handleFetchUpdateSpeaker(prevProps) {
    if (
      prevProps.projectDetailPage.updateSpeaker.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.projectDetailPage.updateSpeaker.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.projectDetailPage.updateSpeaker.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.updateSpeaker.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
    }
    if (
      prevProps.projectDetailPage.updateSpeaker.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.updateSpeaker.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.handleShowDefaultFailureModal(this.props.projectDetailPage.updateSpeaker.error.status)
    }
  }

  _handleFetchCreateSpeaker(prevProps) {
    if (
      prevProps.projectDetailPage.createSpeaker.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.projectDetailPage.createSpeaker.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.projectDetailPage.createSpeaker.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.createSpeaker.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
    }
    if (
      prevProps.projectDetailPage.createSpeaker.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.createSpeaker.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.handleShowDefaultFailureModal(this.props.projectDetailPage.createSpeaker.error.status)
    }
  }

  _handleFetchUpdateCamera(prevProps) {
    if (
      prevProps.projectDetailPage.updateCamera.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.projectDetailPage.updateCamera.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.projectDetailPage.updateCamera.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.updateCamera.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
    }
    if (
      prevProps.projectDetailPage.updateCamera.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.updateCamera.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.showDefaultFailureModal(this.props.projectDetailPage.updateCamera.error.status)
    }
  }

  _handleFetchMoveCameraBetweenArea(prevProps) {
    if (
      prevProps.projectDetailPage.moveCameraBetweenArea.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.projectDetailPage.moveCameraBetweenArea.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.projectDetailPage.moveCameraBetweenArea.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.moveCameraBetweenArea.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
    }
    if (
      prevProps.projectDetailPage.moveCameraBetweenArea.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.moveCameraBetweenArea.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.handleShowDefaultFailureModal(this.props.projectDetailPage.moveCameraBetweenArea.error.status)
    }
  }

  _handleFetchDeleteCamera(prevProps) {
    if (
      prevProps.projectDetailPage.deleteCamera.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.projectDetailPage.deleteCamera.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.projectDetailPage.deleteCamera.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.deleteCamera.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
    }
    if (
      prevProps.projectDetailPage.deleteCamera.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.deleteCamera.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.showDefaultFailureModal(this.props.projectDetailPage.deleteCamera.error.status)
    }
  }

  _handleFetchCreateCamera(prevProps) {
    if (
      prevProps.projectDetailPage.createCamera.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.projectDetailPage.createCamera.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.projectDetailPage.createCamera.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.createCamera.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
    }
    if (
      prevProps.projectDetailPage.createCamera.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.createCamera.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.showDefaultFailureModal(this.props.projectDetailPage.createCamera.error.status)
    }
  }

  _handleFetchDeleteArea(prevProps) {
    if (
      prevProps.projectDetailPage.deleteArea.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.projectDetailPage.deleteArea.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.projectDetailPage.deleteArea.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.deleteArea.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
    }
    if (
      prevProps.projectDetailPage.deleteArea.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.deleteArea.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.showDefaultFailureModal(this.props.projectDetailPage.deleteArea.error.status)
    }
  }

  _handleFetchUpdateArea(prevProps) {
    if (
      prevProps.projectDetailPage.updateArea.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.projectDetailPage.updateArea.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.projectDetailPage.updateArea.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.updateArea.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
    }
    if (
      prevProps.projectDetailPage.updateArea.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.updateArea.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.showDefaultFailureModal(this.props.projectDetailPage.updateArea.error.status)
    }
  }

  _handleFetchCreateArea(prevProps) {
    if (
      prevProps.projectDetailPage.createArea.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.projectDetailPage.createArea.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.projectDetailPage.createArea.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.createArea.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
    }
    if (
      prevProps.projectDetailPage.createArea.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.createArea.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.showDefaultFailureModal(this.props.projectDetailPage.createArea.error.status)
    }
  }

  _handleFetchUpdateSite(prevProps) {
    if (
      prevProps.projectDetailPage.updateSite.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.projectDetailPage.updateSite.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.projectDetailPage.updateSite.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.updateSite.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
    }
    if (
      prevProps.projectDetailPage.updateSite.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.updateSite.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.showDefaultFailureModal(this.props.projectDetailPage.updateSite.error.status)
    }
  }

  _handleFetchDeleteSite(prevProps) {
    if (
      prevProps.projectDetailPage.deleteSite.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.projectDetailPage.deleteSite.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.projectDetailPage.deleteSite.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.deleteSite.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
    }
    if (
      prevProps.projectDetailPage.deleteSite.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.deleteSite.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.showDefaultFailureModal(this.props.projectDetailPage.deleteSite.error.status)
    }
  }

  _handleFetchCreateSite(prevProps) {
    if (
      prevProps.projectDetailPage.createSite.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.projectDetailPage.createSite.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.projectDetailPage.createSite.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.createSite.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
    }
    if (
      prevProps.projectDetailPage.createSite.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.createSite.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.showDefaultFailureModal(this.props.projectDetailPage.createSite.error.status)
    }
  }

  _handleFetchLoadListSite(prevProps) {
    if (
      prevProps.projectDetailPage.loadListSite.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.projectDetailPage.loadListSite.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.projectDetailPage.loadListSite.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.loadListSite.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
    }
    if (
      prevProps.projectDetailPage.loadListSite.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.loadListSite.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.showDefaultFailureModal(this.props.projectDetailPage.loadListSite.error.status)
    }
  }

  _handleFetchUpdateProjectDetail(prevProps) {
    if (
      prevProps.projectDetailPage.updateProjectDetail.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.projectDetailPage.updateProjectDetail.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.projectDetailPage.updateProjectDetail.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.updateProjectDetail.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
      this.handleUpdateProjectDetailSuccess()
    }
    if (
      prevProps.projectDetailPage.updateProjectDetail.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.updateProjectDetail.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.showDefaultFailureModal(this.props.projectDetailPage.updateProjectDetail.error.status)
    }
  }

  _handleFetchLoadProjectDetail(prevProps) {
    if (
      prevProps.projectDetailPage.loadProjectDetail.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.projectDetailPage.loadProjectDetail.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.projectDetailPage.loadProjectDetail.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.loadProjectDetail.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
      this.handleLoadProjectDetailSuccess()
    }
    if (
      prevProps.projectDetailPage.loadProjectDetail.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.loadProjectDetail.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.showDefaultFailureModal(this.props.projectDetailPage.loadProjectDetail.error.status)
    }
  }

  _handleFetchDeleteProjectDetail(prevProps) {
    if (
      prevProps.projectDetailPage.deleteProjectDetail.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.projectDetailPage.deleteProjectDetail.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.projectDetailPage.deleteProjectDetail.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.deleteProjectDetail.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.handleDeleteProjectSuccess()
    }
    if (
      prevProps.projectDetailPage.deleteProjectDetail.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectDetailPage.deleteProjectDetail.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.showDefaultFailureModal(this.props.projectDetailPage.deleteProjectDetail.error.status)
    }
  }

  handleShowDefaultFailureModal(statusCode) {
    fetchErrorHandler(
      statusCode,
      {
        401: () => this.forceLogin()
      },
      this.showDefaultFailureModal
    )
  }

  showDefaultFailureModal = () => {
    this.setShowFailureModalData(
      this.getMessage('error_dialog_title_default'),
      this.getMessage('error_dialog_message_default'),
      this.onTryAgainClick,
      this.getMessage('try_again_button')
    )
  }

  forceLogin() {
    this.props.history.push(FRONTEND_ROUTES.loginRoute)
  }

  setShowFailureModalData = (title, message, onPrimaryButtonClick, primaryButtonText) => {
    this.setState({
      failureModalData: {
        show: true,
        title,
        message,
        onPrimaryButtonClick,
        primaryButtonText
      }
    })
  }

  setHideFailureModalData = () => {
    this.setState({
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      }
    })
  }

  onTryAgainClick = () => {
    this.setHideFailureModalData()
    window.location.reload()
  }
  handleDeleteProjectSuccess() {
    this.props.history.replace(FRONTEND_ROUTES.projectManagementRoute)
  }

  handleUpdateProjectDetailSuccess() {
    this.setState({
      projectDetail: this.props.projectDetailPage.projectDetail,
      breadcreumbProjectName: this.props.projectDetailPage.projectDetail.name
    })
  }

  handleLoadProjectDetailSuccess() {
    this.setState({
      projectDetail: this.props.projectDetailPage.projectDetail,
      breadcreumbProjectName: this.props.projectDetailPage.projectDetail.name
    })
  }

  initialCreatingProjectState() {
    this.setState({
      projectDetail: {
        name: this.getMessage('untitle_txt')
      },
      breadcreumbProjectName: this.getMessage('untitle_txt'),
      isEditingProjectName: true
    })
  }

  setShowLoading(status) {
    this.setState({
      showLoading: status
    })
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.currentUser?.language]
    return msg[property]
  }

  handleTopBreadcrumbClicked() {
    this.props.history.push(FRONTEND_ROUTES.projectManagementRoute)
  }

  getBreadcrumb() {
    const breadcrumbData = [
      { text: this.getMessage('page_title'), onClick: () => this.handleTopBreadcrumbClicked() },
      { text: this.state.breadcreumbProjectName, onClick: () => {} }
    ]
    return (
      <div className="project-detail-breadcrumb">
        <Breadcrumb breadcrumbList={breadcrumbData} />
      </div>
    )
  }

  showEditProjectScreenshotModal = () => {
    this.setState({
      showEditProjectScreenshotModal: true,
      projectScreenshotURL: this.state.projectDetail.pre_encode_image || '',
      isProjectScreenshotURLValid: undefined
    })
  }

  getEditProjectScreenshot() {
    const image = (this.state.projectDetail && this.state.projectDetail.pre_encode_image) || Image
    return (
      <div className="project-detail-image-container">
        <img src={image} alt="" />
        <div className="edit-project-screenshot-container">
          <div className="edit-project-screenshot-btn" onClick={() => this.showEditProjectScreenshotModal()}>
            {this.getMessage('edit_txt')}
          </div>
        </div>
      </div>
    )
  }

  handleProjectNameChanged = (e, newValue) => {
    this.setState({
      projectNameInput: newValue
    })
  }

  handleShowDeleteProjectModal = () => {
    this.setState({
      deleteProjectName: '',
      showDeleteProjectModal: true
    })
  }

  handleEditProjectBtnClicked = () => {
    this.setState({
      showUpdateProjectModal: true,
      isProjectNameValid: undefined,
      projectNameInput: this.state.projectDetail.name
    })
  }

  handleCreateNewSiteBtnClicked = () => {
    this.setState({
      showCreateSiteModal: true,
      newSiteName: '',
      isSiteNameValid: undefined
    })
  }

  getProjectDetailSection() {
    const projectName = this.state.projectDetail?.name
    const isAdmin = this.props.currentUser?.admin
    let deleteProjectBtn = null
    if (isAdmin) {
      deleteProjectBtn = (
        <Button
          id="project-detail-delete-project"
          className="project-detail-btn"
          onClick={this.handleShowDeleteProjectModal}
          text={this.getMessage('delete_project_btn_txt')}
          invert
        />
      )
    }
    return (
      <div className="project-detail-section-container">
        {this.getEditProjectScreenshot()}
        <div className="project-detail-edit-container">
          <div className="project-detail-container">
            <div className="project-detail prevent-text-overflow">{projectName}</div>
            <TextButton label={this.getMessage('edit_txt')} onClick={this.handleEditProjectBtnClicked} primary />
          </div>
          <div className="project-detail-btn-container">
            <Button
              id="project-detail-create-site"
              className="project-detail-btn"
              onClick={this.handleCreateNewSiteBtnClicked}
              text={this.getMessage('add_new_site_btn_txt')}
            />
            {deleteProjectBtn}
          </div>
        </div>
      </div>
    )
  }

  getLoading() {
    let output = null
    if (this.state.showLoading) {
      output = (
        <ModalOverlay>
          <Spinner />
        </ModalOverlay>
      )
    }
    return output
  }

  handleDeleteBtnClicked = () => {
    this.props.deleteProjectDetail(this.props.match.params.projectID)
  }

  handleDeleteProjectNameChanged = (e) => {
    this.setState({
      deleteProjectName: e.target.value
    })
  }

  getDeleteProjectModal() {
    let output = null
    if (this.state.showDeleteProjectModal) {
      output = (
        <ModalOverlay onClose={this.handleCloseDeleteProjectModal}>
          <div className="modal-delete-project-container" onClick={(e) => e.stopPropagation()}>
            <div className="title-container">{this.getMessage('delete_txt')}</div>
            <div className="message-container">
              {this.getMessage('delete_project_msg_01')}
              <p>{this.props.projectDetailPage.projectDetail.name} ?</p>
              {this.getMessage('delete_project_msg_02')}
            </div>
            <div className="text-input-wrapper">
              <TextInput
                id="delete-project-name-input"
                label={this.getMessage('project_name_input_label')}
                mode="material-design"
                placeHolder={this.getMessage('project_name_input_placeholder')}
                onChange={this.handleDeleteProjectNameChanged}
                type="text"
                value={this.state.deleteProjectName}
              />
            </div>
            <div className="btn-group-container">
              <Button
                id="delete-project-cancel-btn"
                onClick={this.handleCloseDeleteProjectModal}
                text={this.getMessage('delete_site_cancel_btn')}
                invert
              />
              <Button
                id="delete-project-delete-btn"
                onClick={this.handleDeleteBtnClicked}
                text={this.getMessage('delete_site_confirm_btn')}
                disabled={this.state.deleteProjectName !== this.props.projectDetailPage.projectDetail.name}
              />
            </div>
          </div>
        </ModalOverlay>
      )
    }
    return output
  }

  handleCloseDeleteProjectModal = () => {
    this.setState({
      deleteProjectName: '',
      showDeleteProjectModal: false
    })
  }

  handleUpdateSiteName = (name, siteID) => {
    const data = {
      site: {
        name: name.trim()
      }
    }
    this.props.updateSite(data, siteID)
  }

  handleUpdateAreaName = (name, areaID, siteID) => {
    const data = {
      area: {
        name: name.trim(),
        site_id: siteID
      }
    }
    this.props.updateArea(data, areaID, siteID)
  }

  handleDeleteSiteClicked = (siteID) => {
    this.props.deleteSite(siteID)
  }

  handleAddCameraBetweenArea = (sourceCameraID, sourceAreaID, targetAreaID, siteID) => {
    const data = {
      camera: {
        area_id: targetAreaID
      }
    }
    this.props.moveCameraBetweenArea(data, sourceCameraID, sourceAreaID)
  }

  handleCreateNewCamera = (cameraData, areaID, siteID) => {
    const projectID = this.props.match.params.projectID
    const data = {
      camera: {
        name: cameraData.camera_name.trim(),
        project_id: projectID,
        site_id: siteID,
        area_id: areaID,
        rtsp_address: cameraData.rtsp_address.trim(),
        gps: `${cameraData.latitude.trim()},${cameraData.longtitude.trim()}`,
        reading_fps: cameraData.reading_fps.trim(),
        scale_factor: cameraData.scale_factor.trim(),
        motion_threshold: cameraData.motion_threshold.trim(),
        num_grids_threshold: cameraData.number_grids_threshold.trim(),
        resolution_width: cameraData.resolution_width.trim(),
        resolution_height: cameraData.resolution_height.trim(),
        violation_time: cameraData.violation_time.trim(),
        violation_count: cameraData.violation_count.trim(),
        is_vehicle_detection: cameraData.is_vehicle_detection
      }
    }
    this.props.createCamera(data)
  }

  handleCreateNewArea = (areaName, siteID) => {
    const projectID = this.props.match.params.projectID
    const data = {
      area: {
        name: areaName.trim(),
        site_id: siteID,
        project_id: projectID
      }
    }
    this.props.createArea(data, siteID)
  }

  handleDeleteArea = (areaID, siteID) => {
    this.props.deleteArea(areaID, siteID)
  }

  handleDeleteCamera = (cameraID, areaID, siteID) => {
    this.props.deleteCamera(cameraID, areaID, siteID)
  }

  handleUpdateCamera = (cameraData, areaID, siteID) => {
    const projectID = this.props.match.params.projectID
    const data = {
      camera: {
        name: cameraData.camera_name.trim(),
        project_id: projectID,
        site_id: siteID,
        area_id: areaID,
        rtsp_address: cameraData.rtsp_address.trim(),
        gps: `${cameraData.latitude.trim()},${cameraData.longtitude.trim()}`,
        reading_fps: cameraData.reading_fps.trim(),
        scale_factor: cameraData.scale_factor.trim(),
        motion_threshold: cameraData.motion_threshold.trim(),
        num_grids_threshold: cameraData.number_grids_threshold.trim(),
        resolution_width: cameraData.resolution_width.trim(),
        resolution_height: cameraData.resolution_height.trim(),
        prev_resolution_width: cameraData.prev_resolution_width.trim(),
        prev_resolution_height: cameraData.prev_resolution_height.trim(),
        violation_count: cameraData.violation_count.trim(),
        violation_time: cameraData.violation_time.trim(),
        is_vehicle_detection: cameraData.is_vehicle_detection
      }
    }
    this.props.updateCamera(data, cameraData.camera_id)
  }

  handleUpdateSiteScreenshot = (urlScreenshot, siteID) => {
    const data = {
      site: {
        image: urlScreenshot.trim()
      }
    }
    this.props.updateSite(data, siteID)
  }

  handleCreateNewSpeaker = (speakerData, cameraData, areaID, siteID) => {
    const data = {
      speaker: {
        name: speakerData.name.trim(),
        method: speakerData.method.trim(),
        url: speakerData.url.trim(),
        payload: speakerData.payload.trim(),
        camera_id: cameraData.camera_id
      }
    }
    this.props.createSpeaker(data, cameraData, areaID, siteID)
  }

  handleUpdateSpeaker = (speakerData, cameraData, areaID, siteID) => {
    const data = {
      speaker: {
        name: speakerData.name.trim(),
        method: speakerData.method.trim(),
        url: speakerData.url.trim(),
        payload: speakerData.payload.trim()
      }
    }
    this.props.updateSpeaker(data, speakerData.id, cameraData, areaID, siteID)
  }

  handleDeleteSpeaker = (speakerData, cameraData, areaID, siteID) => {
    this.props.deleteSpeaker(speakerData, cameraData, areaID, siteID)
  }

  getProjectListSiteSection() {
    return (
      <div className="project-detail-list-site-container">
        <ProjectEditor
          listSite={this.props.projectDetailPage.listSite}
          onDeleteSite={this.handleDeleteSiteClicked}
          onUpdateSiteName={this.handleUpdateSiteName}
          onUpdateSiteScreenshot={this.handleUpdateSiteScreenshot}
          onCreateNewArea={this.handleCreateNewArea}
          onUpdateAreaName={this.handleUpdateAreaName}
          onDeleteArea={this.handleDeleteArea}
          onAddCameraBetweenArea={this.handleAddCameraBetweenArea}
          onCreateNewCamera={this.handleCreateNewCamera}
          onDeleteCamera={this.handleDeleteCamera}
          onUpdateCameraData={this.handleUpdateCamera}
          onCreateSpeaker={this.handleCreateNewSpeaker}
          onUpdateSpeaker={this.handleUpdateSpeaker}
          onDeleteSpeaker={this.handleDeleteSpeaker}
          language={this.props.currentUser.language}
          currentUser={this.props.currentUser}
          isRecalculatingZone={this.props.projectDetailPage.isCalculating}
        />
      </div>
    )
  }

  handleCloseCreateSiteModal = () => {
    this.setState({
      showCreateSiteModal: false,
      newSiteName: '',
      isSiteNameValid: undefined
    })
  }

  handleNewSiteNameChanged = (e, newValue) => {
    this.setState({
      newSiteName: newValue
    })
  }

  _isSiteNameValid = () => {
    const name = this.state.newSiteName.trim()
    const isSiteNameValid = name.length > 0
    this.setState({ isSiteNameValid })
    return isSiteNameValid
  }

  handleSaveCreateNewSiteBtnClicked = () => {
    if (this._isSiteNameValid()) {
      const name = this.state.newSiteName.trim()
      const data = {
        site: {
          name,
          project_id: this.props.match.params.projectID
        }
      }
      this.props.createSite(data)
      this.handleCloseCreateSiteModal()
    }
  }

  _isProjectNameValid = () => {
    const name = this.state.projectNameInput.trim()
    const isProjectNameValid = name.length > 0
    this.setState({ isProjectNameValid })
    return isProjectNameValid
  }

  handleUpdateProjectNameBtnClicked = () => {
    if (this._isProjectNameValid()) {
      const data = {
        project: {
          name: this.state.projectNameInput.trim()
        }
      }
      this.props.updateProjectDetail(this.props.match.params.projectID, data)
      this.handleCloseUpdateProjectModal()
    }
  }

  handleCloseUpdateProjectModal = () => {
    this.setState({
      projectNameInput: '',
      showUpdateProjectModal: false,
      isProjectNameValid: undefined
    })
  }

  getCreateSiteModal() {
    let output = null
    if (this.state.showCreateSiteModal) {
      output = (
        <ModalOverlay onOverlayClicked={this.handleCloseCreateSiteModal} onClose={this.handleCloseCreateSiteModal}>
          <div className="modal-content-container" onClick={(e) => e.stopPropagation()}>
            <div>
              <div className="modal-content-row-container">
                <TextInput
                  mode="material-design"
                  type="text"
                  label={this.getMessage('input_site_name_label')}
                  id="create-site-input"
                  placeHolder={this.getMessage('input_site_name_placeholder')}
                  value={this.state.newSiteName}
                  onChange={this.handleNewSiteNameChanged}
                  errorText={this.state.isSiteNameValid === false ? this.getMessage('dialog_create_site_error_txt') : ''}
                />
              </div>
            </div>
            <Button
              className="full-width"
              id="create-site-save-btn"
              onClick={this.handleSaveCreateNewSiteBtnClicked}
              text={this.getMessage('save_txt')}
            />
          </div>
        </ModalOverlay>
      )
    }
    return output
  }

  getUpdateProjectNameModal() {
    let output = null
    if (this.state.showUpdateProjectModal) {
      output = (
        <ModalOverlay onOverlayClicked={this.handleCloseUpdateProjectModal} onClose={this.handleCloseUpdateProjectModal}>
          <div className="modal-content-container" onClick={(e) => e.stopPropagation()}>
            <div>
              <div className="modal-content-row-container">
                <TextInput
                  mode="material-design"
                  type="text"
                  label={this.getMessage('project_name_input_label')}
                  id="edit-project-name-input"
                  placeHolder={this.getMessage('project_name_input_placeholder')}
                  value={this.state.projectNameInput}
                  onChange={this.handleProjectNameChanged}
                  errorText={this.state.isProjectNameValid === false ? this.getMessage('dialog_update_project_error_txt') : ''}
                />
              </div>
            </div>
            <Button
              className="full-width"
              id="update-project-save-btn"
              onClick={this.handleUpdateProjectNameBtnClicked}
              text={this.getMessage('save_txt')}
            />
          </div>
        </ModalOverlay>
      )
    }
    return output
  }

  handleCloseEditProjectScreenshotModal = () => {
    this.setState({
      showEditProjectScreenshotModal: false,
      isProjectScreenshotURLValid: undefined,
      projectScreenshotURL: ''
    })
  }

  _isProjectScreenshotURLValid = () => {
    const name = this.state.projectScreenshotURL.trim()
    const isProjectScreenshotURLValid = name.length > 0
    this.setState({ isProjectScreenshotURLValid })
    return isProjectScreenshotURLValid
  }

  handleEditProjectScreenshotBtnClicked = () => {
    if (this._isProjectScreenshotURLValid()) {
      const data = {
        project: {
          image: this.state.projectScreenshotURL.trim()
        }
      }
      this.props.updateProjectDetail(this.props.match.params.projectID, data)
      this.handleCloseEditProjectScreenshotModal()
    }
  }

  handleProjectScreenshotChanged = (e, newValue) => {
    this.setState({
      projectScreenshotURL: newValue
    })
  }

  getEditProjectScreenshotModal() {
    let output = null
    if (this.state.showEditProjectScreenshotModal) {
      output = (
        <ModalOverlay onOverlayClicked={this.handleCloseEditProjectScreenshotModal} onClose={this.handleCloseEditProjectScreenshotModal}>
          <div className="modal-content-container" onClick={(e) => e.stopPropagation()}>
            <div>
              <div className="modal-content-row-container">
                <TextInput
                  mode="material-design"
                  type="text"
                  label={this.getMessage('project_screenshot_input_label')}
                  id="edit-project-name-input"
                  placeHolder={this.getMessage('project_screenshot_input_placeholder')}
                  value={this.state.projectScreenshotURL}
                  onChange={this.handleProjectScreenshotChanged}
                  errorText={this.state.isProjectScreenshotURLValid === false ? this.getMessage('dialog_update_project_img_url_error_txt') : ''}
                />
              </div>
            </div>
            <Button
              className="full-width"
              id="update-project-save-btn"
              onClick={this.handleEditProjectScreenshotBtnClicked}
              text={this.getMessage('save_txt')}
            />
          </div>
        </ModalOverlay>
      )
    }
    return output
  }

  getFailureModal() {
    let output = null
    if (this.state.failureModalData.show) {
      output = (
        <ModalNotification
          className={'default-failure-modal'}
          title={this.state.failureModalData.title}
          message={this.state.failureModalData.message}
          onPrimaryButtonClick={this.state.failureModalData.onPrimaryButtonClick}
          primaryButtonText={this.state.failureModalData.primaryButtonText}
        />
      )
    }
    return output
  }

  render() {
    return (
      <ProjectManagementDetailStyled>
        <div className="project-detail-page-container">
          {this.getBreadcrumb()}
          {this.getProjectDetailSection()}
          {this.getProjectListSiteSection()}
        </div>
        {this.getCreateSiteModal()}
        {this.getUpdateProjectNameModal()}
        {this.getDeleteProjectModal()}
        {this.getEditProjectScreenshotModal()}
        {this.getLoading()}
        {this.getFailureModal()}
      </ProjectManagementDetailStyled>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.loginPage.loginAPI.userData,
    projectDetailPage: state.projectDetailPage
  }
}

ProjectPageLayout.defaultProps = {
  projectDetailPage: {
    projectDetail: {
      name: 'Project name'
    }
  }
}

ProjectPageLayout.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  projectDetailPage: PropTypes.object.isRequired,
  loadProjectDetail: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Object.assign({}, ProjectActions), dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPageLayout)
