import styled from 'styled-components'

export default styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  .project-detail-page-container {
    max-width: 1240px;
    height: 100%;
    margin: 0 auto;
    padding-top: 30px;
    .project-detail-breadcrumb {
      margin-bottom: 20px;
    }
    .project-detail-section-container {
      display: flex;
      padding: 0 25px;
      .project-detail-image-container {
        position: relative;
        width: 140px;
        height: 140px;
        border-radius: 5px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
        margin-right: 45px;
        background: ${(props) => props.theme.greyDisabled};
        img {
          border-radius: 5px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .edit-project-screenshot-container {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: calc(50% - 15px);
          width: 100%;
          height: 30px;
          background: rgba(0, 0, 0, 0.3);
          .edit-project-screenshot-btn {
            font-family: 'Prompt', sans-serif;
            font-size: 10px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: center;
            color: white;
            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }
      .project-detail-edit-container {
        margin-bottom: 70px;
        .project-detail-container {
          display: flex;
          align-items: center;
          margin-bottom: 38px;
          .project-detail {
            max-width: 350px;
            flex: 1;
            height: 60px;
            font-family: 'Prompt', sans-serif;
            font-size: 40px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            background: transparent;
            border: none;
            color: white;
            margin-right: 20px;
            outline: none;
          }
          .btn-mode {
            font-size: 18px;
          }
        }
        .project-detail-btn-container {
          display: flex;
          .project-detail-btn {
            width: 190px;
            font-size: 14px;
            &:first-child {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
`
